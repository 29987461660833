import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import HeadHelmet from "../components/HeadHelmet/HeadHelmet";

// import imgFeatured from "../assets/image/snorefreeswiss/bite-snoring-custom-magnet.jpg";


const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
      >
        <HeadHelmet
          title='about_us_title'
          description='about_us_b1'
          //image={imgFeatured}
        />

        <div className="inner-banner pt-20 pb-8">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h1 className="title gr-text-2 mb-9 heading-color">
                    {t('about_us_title')}
                  </h1>
                </div>
              </Col>
              {/* <Col xs="10">
                <div className="pt-lg-9 pb-8">
                  <img src={imgFeatured} alt={t('about_us_title') } className="w-100" />
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9 text-color-opacity">
                      {t('about_us_b1')} </p>
                    <p className="gr-text-9 text-color-opacity">
                      {t('about_us_b2')} </p>
                    <p className="gr-text-9 text-color-opacity">
                      {t('about_us_b3')} </p>
                    <p className="gr-text-9 text-color-opacity">
                      {t('about_us_b4')} </p>

                  </div>
                </div>
                <div className="pt-10">
                  <Link
                    to="/anti-snore-bite-explained/"
                    className="btn-link with-icon text-blue gr-hover-y px-lg-8 px-xl-3"
                  >
                      {t('snore_bite_title')}&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div className="pt-10 pb-20">
                  <Link
                    to="/why-do-we-snore/"
                    className="btn-link with-icon text-blue gr-hover-y px-lg-8 px-xl-3"
                  >
                    {t('why_snore_title')}&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link>
                </div>

              </Col>

            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
